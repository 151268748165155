// extracted by mini-css-extract-plugin
export var container = "Testimonials-module--container--1FSUY";
export var content = "Testimonials-module--content--25JRJ";
export var title = "Testimonials-module--title--141IN";
export var titleMobile = "Testimonials-module--titleMobile--2Uhql";
export var section = "Testimonials-module--section--ox3JH";
export var sectionInfo = "Testimonials-module--sectionInfo--2Eo0i";
export var sectionText = "Testimonials-module--sectionText--3vSTI";
export var sectionTextCenter = "Testimonials-module--sectionTextCenter--2g2pf";
export var testimonialsContainer = "Testimonials-module--testimonialsContainer--xSUe5";
export var testimonial = "Testimonials-module--testimonial--KL49_";
export var testimonialImg = "Testimonials-module--testimonialImg--1DrO5";
export var testimonialTitle = "Testimonials-module--testimonialTitle--1Pq-V";
export var button = "Testimonials-module--button--xcOh3";
export var buttonToTop = "Testimonials-module--buttonToTop--1H494";
export var buttonToTopHidden = "Testimonials-module--buttonToTopHidden--Q_dXn";